import * as React from "react";
import { Helmet } from "react-helmet";
import {
  Link,
  CardActionArea
} from "gatsby-theme-material-ui";

import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import MenuIcon from '@material-ui/icons/Menu';

import mastheadImage from "../images/bg2021.jpg";

// styles
const masthead = {
  margin: 0,
  backgroundImage: `url(${mastheadImage})`,
  backgroundColor: '#666',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  width: '100%',
  aspectRatio: '1 / 1',
  color: 'rgba(255, 255, 255, 0.66)',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
};

// navigation
const navigation = [
  {
    title: 'Home',
    description: '',
    action: {
      text: 'Go home',
      href: '/',
    }
  },
  {
    title: "Pictures",
    description: "Some pictures.",
    action: {
      text: "See them",
      href: '/pictures'
    }
  },
  {
    title: "Blog",
    description: "Family musings.",
    action: {
      text: 'Read them',
      href: '/blog'
    }
  },
  {
    title: "Brew log",
    description: "Mike's homebrewing log.",
    action: {
      text: 'Relax, don\'t worry, have a homebrew',
      href: '/brewlog'
    }
  }
];

function DefaultHeader({ subtitle }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header>
      <AppBar position="static" elevation={0} color='transparent'>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="span">
            <Link to="/">MaMaSaR</Link> / {subtitle}
          </Typography>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {
              navigation.map(
                nav => (
                  <MenuItem key={nav.title} component={Link} to={nav.action.href}>
                    {nav.title}
                  </MenuItem>
                )
              )
            }
          </Menu>
        </Toolbar>
      </AppBar>
    </header>
  );
}

function HomeHeader() {
  return (
    <header>
      <Typography variant="h1" sx={masthead}>
        Martha and Mike and Shea and Rocky
      </Typography>
      <Grid container component='nav' spacing={2} p={2} sx={{ background: '#777' }}>
        {
          navigation.filter((nav) => nav.title !== 'Home').map(
            nav => (
              <Grid item xs={12} sm={4} key={nav.title}>
                <Card>
                  <CardActionArea to={nav.action.href}>
                    <CardContent>
                      <Typography variant="h5">{nav.title}</Typography>
                      <p>{nav.description}</p>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            )
          )
        }
      </Grid>
    </header >
  );
}

export default function Layout({ home, subtitle, children }) {
  return (
    <>
      <Helmet>
        <title>Martha and Mike {subtitle ? `/ ${subtitle}` : ''}</title>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
      </Helmet>
      <div>
        {home ? <HomeHeader /> : <DefaultHeader subtitle={subtitle} />}
        <main >
          {children}
        </main>
      </div>
    </>
  );
}